import React from 'react'
import { projectImage1, projectImage2, projectImage3 } from '../assets'
import { useNavigate } from 'react-router-dom'


const Projects = () => {

  const navigate = useNavigate()

  return (
    <div className='md:pb-16 mb-16 py-10 border-b-[10px] border-primary md:px-[100px] px-4'>
      <h2 className="md:text-3xl text-xl font-bold">
        Individuals we serve
      </h2>
      <div className="md:pt-10 pt-6 pb-10 grid md:grid-cols-3 grid-cols-1 gap-8">
        <div className="rounded-xl">
          <img src={projectImage1} alt='' className='h-[170px] md:h-[170px] rounded-t-xl' width='' height='' />
          <div className="border-t-4 border-black bg-primary md:p-6 p-4 flex items-center justify-between text-white rounded-b-xl">
            <div className="flex flex-col">
              <h3 className="md:text-xl text-base font-bold flex items-center gap-2 mb-1">
                Adults Who Need Care
              </h3>
              <span className="font-normal md:text-base text-xs">
                We're your go-to company
              </span>
            </div>
          </div>
        </div>
        <div className="rounded-xl">
          <img src={projectImage2} alt='' className='h-[170px] md:h-[170px] rounded-t-xl' width='' height='' />
          <div className="border-t-4 border-black bg-primary md:p-6 p-4 flex items-center justify-between text-white rounded-b-xl">
            <div className="flex flex-col">
              <h3 className="md:text-xl text-base font-bold flex items-center gap-2 mb-1">
                Men & Women Who Need Care  
              </h3>
              <span className="font-normal md:text-sm text-xs">
                We are at your service..
              </span>
            </div>
          </div>
        </div>
        <div className="rounded-xl">
          <img src={projectImage3} alt='' className='h-[170px] md:h-[170px] rounded-t-xl' width='' height='' />
          <div className="border-t-4 border-black bg-primary md:p-6 p-4 flex items-center justify-between text-white rounded-b-xl">
            <div className="flex flex-col">
              <h3 className="md:text-xl text-base font-bold flex items-center gap-2 mb-1">
                Children Who Need Care
              </h3>
              <span className="font-normal md:text-sm text-xs">
                We take good care of kids too..
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          onClick={() => navigate('/contact')}
        >
          Book an Appointment
        </button>
      </div>
    </div>
  )
}

export default Projects