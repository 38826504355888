import React from 'react'
import { statistics } from '../constants'

const Statistics = () => {
  return (
    <div className='bg-primary md:px-[100px] px-4 py-10 flex md:flex-row flex-col md:items-center md:justify-between text-white gap-8 md:mb-16 mb-10'>
      {
        statistics.map(statistic => (
          <div className="flex items-center gap-4" key={statistic.id}>
            <img src={statistic.statImage} alt='' className='md:h-20 md:w-20 h-16 w-16' />
            <div className="flex flex-col">
              <span className="md:text-3xl text-2xl font-bold block">
                {statistic.stat}
              </span>
              <p className="">
                {statistic.statDescription}
              </p>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Statistics