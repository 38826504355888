import React from 'react'
import { About, Contact, Customers, HeroSection, Projects, ServiceTypes, Services, Statistics, Work } from '../components'

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Statistics />
      <Customers />
      <ServiceTypes />
      {/* <Services /> */}
      <Projects />
      <Contact />
      {/* <Work /> */}
      <About />
    </div>
  )
}

export default HomePage