import React from 'react'
import { useNavigate } from 'react-router-dom'


const Work = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col items-center py-[80px] px-4 border-y border-primary text-center'>
      <h2 className="md:text-3xl text-xl font-bold md:mb-4 mb-2">
          About Us
        </h2>
        <p className="mb-6 md:w-[68%] w-full">
          We are a OHCQ licensed Agency provider here in the state of Maryland. We create individualized PCP and Care of Plan to individuals (5-18 years old) & (21 and above) and provide support and resources for healthy living irrespective of age, sex, ethnicity, or religion. We provide services in Allegany, Anne Arundel, Baltimore, Baltimore City, Calvert, Caroline, Carroll, Cecil, Charles, Dorchester, Fredrick, Garrett, Harford, Howard, Kent, Montgomery, Prince, George's, Queen Anne's, St. Mary's, Somerset, Talbot, Washington, Wicomico and Worcester.
        </p>
        <div className="flex items-center">
          <button 
            className="md:py-3 py-2 md:px-12 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </button>
        </div>
    </div>
  )
}

export default Work