import React from 'react'
import { fiveStars, heroBg, multipleDps } from '../assets'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {

  const navigate = useNavigate()

  const styles = {
    backgroundImage: `url(${heroBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div style={styles} className='h-[100vh] md:pt-[80px]'>
      <div className="md:w-[60%] flex flex-col justify-center h-full md:pl-[100px] pl-4">
        {/* <p className="font-bold md:mb-4 mb-1 text-primary">
          Worksites Recruitment Limited
        </p> */}
        <h1 className="text-2xl md:text-5xl md:leading-tight font-bold mb-4">
          Enhancing Your Well-Being Through Personal Care
        </h1>
        <p className="md:w-[78%] w-[95%] mb-6">
          We are an OHCQ licensed agency provider in the state of Maryland. We provide services in Allegany, Anne Arundel, Baltimore, Baltimore City, Calvert, Caroline, Carroll, Cecil, Charles, Dorchester, Fredrick, Garrett, Harford, Howard, Kent, Montgomery, Prince, George's, Queen Anne's, St. Mary's, Somerset, Talbot, Washington, Wicomico and Worcester.
        </p>
        <div className="flex items-center gap-4 mb-6">
          <a 
            className="py-3 md:px-12 px-6 bg-primary hover:bg-black text-white font-semibold"
            href='mailto:rehovotresources.inc@gmail.com?subject=Mail from Rehovoth Websites'
          >
            Free Consultation
          </a>
          <button 
            className="md:py-3 py-2 md:px-12 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </button>
        </div>
        <div className="flex items-center gap-4">
          <img src={multipleDps} alt="" className="h-12" />
          <div className="">
            <span className="block text-sm">
              "Best choice I've made"
            </span>
            <span className="block text-xs font-bold">
              - Dan Smith, CEO compass Inc.
            </span>
            <img src={fiveStars} alt="" className="h-3 mt-1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection