import React from 'react'
import { serviceImage, serviceImage2, serviceImage3, serviceImage4, serviceImage5, serviceImage6, serviceImage7, serviceImage8, serviceImage9 } from '../assets'

const ServiceTypes = () => {
  return (
    <div className='md:mb-[60px] mb-10 md:px-[100px] px-4'>
      <div className="flex flex-col items-center text-center md:mb-[60px] mb-10">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Services we offer
        </h2>
        <p className="md:w-[64%] w-full">
          We accept reimbursement for cost of coverage through private pay check (check,cash,credit card, etc ). Long-term care insurance workers compensation,.Medicaid payment will be accepted in full
        </p>
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Assistance with Activities of Daily Living 
          </h3>
          <p className="md:w-[85%] w-full">
            Assistance with Activities of Daily Living Our caregivers provide personalized support for daily tasks like bathing, dressing, and grooming, ensuring your loved ones maintain their independence and dignity.
          </p>
        </div>
        <img src={serviceImage} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Transferring, Positioning & Mobility Assistance
          </h3>
          <p className="md:w-[85%] w-full">
            Transferring, Positioning & Mobility Assistance We offer safe and gentle assistance with transferring and positioning, allowing for comfortable mobility within the home or during outings.
          </p>
        </div>
        <img src={serviceImage2} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Mealtime Feeding Assistance
          </h3>
          <p className="md:w-[85%] w-full">
            Mealtime Feeding Assistance Our compassionate caregivers provide attentive feeding assistance, ensuring your loved ones receive proper nourishment in a respectful and dignified manner.
          </p>
        </div>
        <img src={serviceImage3} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Alzheimer's/Dementia Care
          </h3>
          <p className="md:w-[85%] w-full">
            Alzheimer's/Dementia Care Specialized care for those with Alzheimer's or dementia, delivered with patience, understanding, and tailored approaches to meet their unique needs.
          </p>
        </div>
        <img src={serviceImage4} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Monitor Dieting & Eating 
          </h3>
          <p className="md:w-[85%] w-full">
            Monitor Dieting & Eating Our caregivers closely monitor dietary needs and eating habits, ensuring your loved ones maintain a balanced and nutritious diet.
          </p>
        </div>
        <img src={serviceImage5} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Light Housekeeping & Laundry 
          </h3>
          <p className="md:w-[85%] w-full">
            Light Housekeeping & Laundry We provide light housekeeping services, including laundry, to maintain a clean and comfortable living environment for your loved ones.
          </p>
        </div>
        <img src={serviceImage6} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Meal Preparation & Cooking
          </h3>
          <p className="md:w-[85%] w-full">
            Meal Preparation & Cooking Our caregivers can prepare delicious, nutritious meals tailored to your loved one's dietary needs and preferences, ensuring proper nourishment.
          </p>
        </div>
        <img src={serviceImage7} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Transport to & from Doctors Appointments
          </h3>
          <p className="md:w-[85%] w-full">
            Transport to & from Doctors Appointments Safe and reliable transportation services to and from medical appointments, providing peace of mind and ensuring timely access to healthcare.
          </p>
        </div>
        <img src={serviceImage8} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Medication Reminders
          </h3>
          <p className="md:w-[85%] w-full">
            Medication Reminders Our caregivers offer medication reminders, helping your loved ones adhere to their prescribed regimen and promoting better health outcomes.
          </p>
        </div>
        <img src={serviceImage9} alt="" className="w-1/2 md:block hidden" />
      </div>
    </div>
  )
}

export default ServiceTypes