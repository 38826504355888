import React from 'react'
import { About, Contact, ServiceTypes, Services, Statistics, Work } from '../components'

const ServicePage = () => {
  return (
    <div className='md:pt-[160px] pt-[110px]'>
      <ServiceTypes />
      {/* <Services /> */}
      <About />
      {/* <Statistics /> */}
      {/* <Work /> */}
      <Contact />
    </div>
  )
}

export default ServicePage