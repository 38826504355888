import React from 'react'
import { About, Customers, Services, Statistics, Vision, Work } from '../components'

const AboutPage = () => {
  return (
    <div className='md:pt-[90px] pt-[70px]'>
      <About />
      <Statistics />
      <Work />
      <Vision />
      {/* <Customers /> */}
      {/* <Services /> */}
    </div>
  )
}

export default AboutPage