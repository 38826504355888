import React from 'react'
import { facebookIcon, instagramIcon, navbarLogo } from '../assets'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="mt-16">
      <div className='flex md:flex-row flex-col items-center md:items-start md:p-[100px] md:text-start text-center px-4 py-16 text-white bg-primary gap-10'>
        <div className="md:w-[40%] w-full flex flex-col items-center md:items-start">
          <img src={navbarLogo} alt="" className="mb-6 md:h-[60px] h-10 md:w-[70px] w-10" />
          <p className="text-sm md:w-[60%] w-[90%] font-bold">
            Partnering to Deliver Excellence...
          </p>
          <div className="flex flex-col gap-1 mt-6">
            <Link to='/terms-and-conditions' className='underline'>
              Terms & Conditions
            </Link>
            <Link to='/privacy-policy' className='underline'>
              Privacy Policies
            </Link>
            <Link to='/copyright' className='underline'>
              Copyrights
            </Link>

          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center md:items-start text-center md:text-start md:gap-24 gap-10">
          <div className="">
            <p className="font-bold mb-6">
              Address
            </p>
            <div className="flex flex-col items-center md:items-start gap-4">
              <p className="text-sm">
                50, Hamlet Dr, owings mills, 
              </p>
              <p className="text-sm">
                P.O Box: 21117 United States. 
              </p>
              <div className="bg-white h-[2px] w-20 flex justify-center"></div>
              <p className="text-sm">
                (443) 843-0476, (443) 740-3712, +1 (667) 460-8236
              </p>
              <p className="text-sm">
                rehovotresources.inc@gmail.com
              </p>
            </div>
          </div>
          <div className="">
            <p className="font-bold mb-6">
              Operating Hours
            </p>
            <div className="flex flex-col gap-2">
              <p className="text-sm">
                Monday: 9am - 5pm 
              </p>
              <p className="text-sm">
                Tuesday: 9am - 5pm 
              </p>
              <p className="text-sm">
                Wednesday: 9am - 5pm 
              </p>
              <p className="text-sm">
                Thursday: 9am - 5pm 
              </p>
              <p className="text-sm">
                Friday: 9am - 5pm 
              </p>
              <p className="text-sm">
                Saturday: 10am - 4pm
              </p>
              <div className="flex items-center gap-4 md:justify-start justify-center mt-4">
                <img src={facebookIcon} alt="" className="w-6 h-6" />
                <a href='https://www.instagram.com/worksitesrecruitment?igsh=eTdjcnUxenNvcXN2' target='blank' >
                  <img src={instagramIcon} alt="" className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-4 bg-red-50 text-gray-700 text-center">
        <p className="text-xs md:text-base">
          &copy; 2024 for Rehovot Resources inc.
        </p>
      </div>
    </div>
  )
}

export default Footer