import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { closeIcon, navbarLogo, menuIcon } from '../assets'
import { navLinks } from '../constants'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {


  const [toggle, setToggle] = useState(false)

  const navigate = useNavigate()


  return (
    <div className='fixed left-0 right-0 top-0 bg-white flex items-center justify-between md:px-[100px] px-4 md:py-6 py-4 shadow-lg'>
      <Link to='/'>
        <img src={navbarLogo} alt="" className="md:h-[60px] h-10 md:w-[70px] w-10" />
      </Link>
      <div className="md:flex hidden items-center gap-[120px]">
        <div className="flex items-center gap-16 font-normal text-lg">
          {
            navLinks.map(navLink => (
              <NavLink 
                to={navLink.path}
                className='hover:text-primary font-medium'
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#018045" : '',
                  };
                }}
              >
                {navLink.linkName}
              </NavLink>
            ))
          }
        </div>
        <div className="flex items-center gap-4">
          <a 
            href='mailto:rehovotresources.inc@gmail.com?subject=Mail from Rehovoth Websites'     
            className="md:py-3 py-2 md:px-6 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          >
            Free Consultation
          </a>
          <button 
            className="md:py-3 py-2 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </button>
        </div>
      </div>

      <div className="block md:hidden">
        <img
          src={toggle ? closeIcon : menuIcon} className='md:hidden block h-6 w-6 cursor-pointer'
          alt=''
          onClick={() => setToggle(!toggle)}
        />
        <div
          className={`${toggle ? 'flex' : 'hidden'} justify-center items-center h-[95vh] bg-black bg-opacity-90 text-white absolute top-20 right-0 -my-2 w-full`}
        >
          <ul
            className="list-none items-center flex flex-col gap-10 pb-[24px]"
          >
            {
              navLinks.map(navLink => (
                <NavLink
                  to={navLink.path}
                  className='hover:text-primary font-medium'
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#018045" : '',
                    };
                  }}
                  onClick={() => setToggle(false)}
                >
                  {navLink.linkName}
                </NavLink>
              ))
            }
            <div className="flex flex-col gap-6">
              <a
                className="py-3 px-12 bg-primary hover:bg-black text-white font-semibold"
                href='mailto:rehovotresources.inc@gmail.com?subject=Mail from Rehovoth Websites'
              >
                Free Consultation
              </a>
              <button 
                className="md:py-3 py-2 md:px-12 px-6 text-white bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-white hover:text-black"
                onClick={() => {
                  navigate('/contact')
                  setToggle(false)
                }}
                >
                Contact Us
              </button>
            </div>

          </ul>
        </div>
      </div>


    </div>
  )
}

export default Navbar