import React from 'react'
import { aboutImage } from '../assets'
import { useNavigate } from 'react-router-dom'

const About = () => {
  const navigate = useNavigate()

  return (
    <div className='bg-[#F9F9F9] md:px-[100px] px-4 md:py-16 py-10 border-b-[10px] border-primary flex items-center gap-12'>
      <img src={aboutImage} alt="" className="md:block hidden w-[35%]" />
      <div className="">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Our Mission
        </h2>
        <p className="mb-6">
          At Rehovot Resources, Inc., our unwavering mission is to empower lives with compassionate care and unwavering support. We are dedicated to providing personalized healthcare solutions that enrich the well-being of our clients, enabling them to thrive in the comfort and familiarity of their own homes.
          <br /><br /> 
          We pledge to be a beacon of trust, fostering lasting relationships with our clients and their families. Our commitment to excellence drives us to deliver the highest quality nursing and healthcare services, harnessing the latest medical advancements and technologies to ensure the utmost satisfaction and well-being of those we serve. <span className="font-bold">-Rehovot Resources, Inc.</span>
        </p>
        <button 
          className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          onClick={() => navigate('/contact')}
        >
          Contact Us
        </button>
      </div>
    </div>
  )
}

export default About