import React from 'react'
import { visionImage } from '../assets'

const Vision = () => {

  const styles = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${visionImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div style={styles} className='md:mx-[100px] mx-4 md:h-[250px] mt-[60px] flex flex-col justify-center rounded-lg text-white md:px-[100px] px-4 md:py-0 py-10'>
      <h2 className="md:text-3xl text-xl font-bold md:mb-4 mb-2">
          Our Vision
        </h2>
        <p className="md:w-[100%] w-full">
          Our vision is to create a world where children, adults (5-18 years old) & (21 and above) who need nursing & home care can enjoy in-home support, resources and community integration. We envision a world where they can live their lives to the fullest and with dignity, choice and independence.
        </p>
    </div>
  )
}

export default Vision