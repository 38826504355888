import React from 'react'
import { Contact } from '../components'

const ContactPage = () => {
  return (
    <div className='md:pt-[80px] pt-[50px]'>
      <Contact />
    </div>
  )
}

export default ContactPage