import { projectIcon, ratingIcon, satisfactionIcon } from "../assets";

export const navLinks = [
  {
    id: 1,
    linkName: 'Home',
    path: '/'
  },
  {
    id: 2,
    linkName: 'Services',
    path: '/services'
  },
  {
    id: 3,
    linkName: 'About',
    path: '/about'
  },
  {
    id: 4,
    linkName: 'Contact',
    path: '/contact'
  },
];

export const statistics = [
  {
    id: 1,
    stat: '100%',
    statDescription: 'Client Oriented',
    statImage: projectIcon,
  },
  {
    id: 2,
    stat: 'Very',
    statDescription: 'Professional',
    statImage: satisfactionIcon,
  },
  {
    id: 3,
    stat: '100%',
    statDescription: 'Clients Satisfaction',
    statImage: ratingIcon,
  },
]

export const customers = [
  {
    id: 1,
    customerName: 'Dr. Thoyinstar, CEO of Compass Inc',
    summary: "We serve in developing and maintaining skills related to personal health care needs.. We will provide services at any hour of the day and any day of the week, including holidays."
  },
  {
    id: 2,
    customerName: 'Dr. DoyinMoon, CEO of Mathset Inc',
    summary: "We serve in developing and maintaining skills related to community membership.."
  },
]